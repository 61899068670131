<template>
  <form-field
    :name="field.name"
    :hide="field.hide"
  >
    <v-select
      v-model="field.value"
      :items="field.props.list"
      v-bind="field.props"
      :menu-props="{ maxHeight:'270' }"
      dense
      box
      validate-on-blur
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  name: 'DealerAppointment',
  mixins: [formField],
}
</script>
